import React, { useState, useRef } from "react";
import { motion, useInView, AnimatePresence } from "framer-motion";
import Slider from "./Slider";

const Art = () => {
  const titleRef = useRef(null);
  const filtersRef = useRef(null);
  const galleryRef = useRef(null);
  
  const isTitleInView = useInView(titleRef, { once: false, amount: 0.3 });
  const isFiltersInView = useInView(filtersRef, { once: false, amount: 0.4 });
  const isGalleryInView = useInView(galleryRef, { once: false, amount: 0.1 });
  
  // Animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };
  
  const descriptionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, delay: 0.2, ease: "easeOut" }
    }
  };
  
  const filtersVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.6, 
        staggerChildren: 0.1,
        ease: "easeOut" 
      }
    }
  };
  
  const filterButtonVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.4 }
    }
  };
  
  const galleryVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" }
    }
  };

  const artworks = [
    {
      img: "/art/IMG_0460.jpg",
      title: "Tokyo, Japan",
      type: "photos"
    },
    {
      img: "/art/IMG_0547.jpg",
      title: "Tokyo, Japan",
      type: "photos"
    },
    {
      img: "/art/IMG_2622.jpg",
      title: "San Juan, Puerto Rico",
      type: "photos"
    },
    {
      img: "/art/IMG_7933.jpg",
      title: "Philadelphia, PA",
      type: "photos"
    },
    {
      img: "/art/IMG_7645.jpg",
      title: "Philadelphia, PA",
      type: "photos"
    },
    {
      img: "/art/IMG_4750.jpg",
      title: "Moorea, French Polynesia",
      type: "photos"
    },
    {
      img: "/art/IMG_4637.jpg",
      title: "Moorea, French Polynesia",
      type: "photos"
    },
    {
      img: "/art/IMG_6109.JPG",
      title: "Saint Petersburg, FL",
      type: "photos"
    },
    {
      img: "/art/IMG_7612.jpg",
      title: "Calculated Risk",
      type: "drawings"
    },
    {
      img: "/art/IMG_7886.jpg",
      title: "Connected",
      type: "drawings"
    },
    {
      img: "/art/IMG_8044.jpg",
      title: "Space Oddity 2020",
      type: "drawings"
    },
    {
      img: "/art/IMG_7731.jpg",
      title: "College Living",
      type: "drawings"
    },
    {
      img: "/art/1_1.jpg",
      title: "Sound as Art",
      type: "designs"
    },
    {
      img: "/art/1_2.jpg",
      title: "Sound as Art (Final)",
      type: "designs"
    },
    {
      img: "/art/2_1.jpg",
      title: "Poetry as Art",
      type: "designs"
    },
    {
      img: "/art/2_2.jpg",
      title: "Poetry as Art (Final)",
      type: "designs"
    },
    {
      img: "/art/radio.jpg",
      title: "Photo Radio",
      type: "designs"
    },
    {
      img: "/art/shoes.jpg",
      title: "Walking Shoes",
      type: "designs"
    },
  ];

  const [filter, setFilter] = useState("all");
  const filteredArtworks = filter === "all" ? artworks : artworks.filter(item => item.type === filter);

  return (
    <section className="section bg-gradient-to-b from-primary to-secondary" id="art">
      <div className="container">
        <div className="mb-12 text-center" ref={titleRef}>
          <motion.h2 
            className="section-title mx-auto"
            variants={titleVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            Art Portfolio
          </motion.h2>
          <motion.p 
            className="mt-4 text-neutral-300 max-w-xl mx-auto"
            variants={descriptionVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            Throughout my college career, I've found that the work I've enjoyed the most comes at the intersection of my programming and art interests – making something that is aesthetically pleasing and technologically advanced.
          </motion.p>
        </div>

        <motion.div 
          className="filter-buttons flex flex-wrap justify-center gap-3 mb-12"
          ref={filtersRef}
          variants={filtersVariants}
          initial="hidden"
          animate={isFiltersInView ? "visible" : "hidden"}
        >
          <motion.button
            variants={filterButtonVariants}
            onClick={() => setFilter("all")}
            className={`px-4 py-2 rounded-full transition-all ${
              filter === "all"
                ? "bg-accent text-white"
                : "bg-secondary-light text-neutral-300 hover:bg-accent/20"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            All
          </motion.button>
          <motion.button
            variants={filterButtonVariants}
            onClick={() => setFilter("photos")}
            className={`px-4 py-2 rounded-full transition-all ${
              filter === "photos"
                ? "bg-accent text-white"
                : "bg-secondary-light text-neutral-300 hover:bg-accent/20"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Photography
          </motion.button>
          <motion.button
            variants={filterButtonVariants}
            onClick={() => setFilter("drawings")}
            className={`px-4 py-2 rounded-full transition-all ${
              filter === "drawings"
                ? "bg-accent text-white"
                : "bg-secondary-light text-neutral-300 hover:bg-accent/20"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Drawings
          </motion.button>
          <motion.button
            variants={filterButtonVariants}
            onClick={() => setFilter("designs")}
            className={`px-4 py-2 rounded-full transition-all ${
              filter === "designs"
                ? "bg-accent text-white"
                : "bg-secondary-light text-neutral-300 hover:bg-accent/20"
            }`}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Designs
          </motion.button>
        </motion.div>

        <motion.div 
          className="art-gallery"
          ref={galleryRef}
          variants={galleryVariants}
          initial="hidden"
          animate={isGalleryInView ? "visible" : "hidden"}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={filter}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.4 }}
            >
              <Slider projects={filteredArtworks} />
            </motion.div>
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default Art;
