import React from "react";
import { motion } from "framer-motion";
import HeroImg from "../assets/hero.png";
import { AiOutlineLinkedin, AiOutlineGithub, AiOutlineArrowDown } from "react-icons/ai";

const Hero = () => {
  // Function to handle scroll to about section
  const handleScrollToAbout = (e) => {
    e.preventDefault();
    const aboutSection = document.getElementById('about');
    if (aboutSection) {
      window.scrollTo({
        top: aboutSection.offsetTop - 80, // Account for header height
        behavior: 'smooth'
      });
    }
  };

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const imageContainerVariants = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { 
        duration: 0.7,
        ease: "easeOut"
      }
    }
  };

  return (
    <section className="section bg-gradient-to-b from-primary-dark to-primary" id="home">
      <div className="container h-full flex flex-col justify-center">
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <motion.div 
            className="hero-content order-2 lg:order-1 text-center lg:text-left"
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <motion.div 
              className="relative inline-block mb-4 px-3 py-1 rounded-full bg-accent/10 text-accent text-sm font-medium select-none"
              variants={itemVariants}
            >
              <span>Software Engineer</span>
            </motion.div>
            
            <motion.h1 
              className="mb-6 text-neutral-50"
              variants={itemVariants}
            >
              Hi, I'm <span className="text-accent">Porter Killian</span>
            </motion.h1>
            
            <motion.p 
              className="mb-8 text-neutral-300 max-w-xl"
              variants={itemVariants}
            >
              A passionate software engineer focused on building beautiful, functional, and user-friendly digital experiences.
            </motion.p>
            
            <motion.div 
              className="flex flex-wrap gap-4 mb-8 justify-center lg:justify-start select-none"
              variants={itemVariants}
            >
              <motion.a
                href="/#projects"
                className="btn btn-primary select-none"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                View Projects
              </motion.a>
              <motion.a
                href="/#contact"
                className="btn btn-outline select-none"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Contact Me
              </motion.a>
            </motion.div>
            
            <motion.div 
              className="flex items-center gap-4 text-lg justify-center lg:justify-start select-none"
              variants={itemVariants}
            >
              <motion.a
                href="https://www.linkedin.com/in/porter27/"
                target="_blank"
                rel="noreferrer"
                className="text-neutral-400 hover:text-accent transition-colors"
                aria-label="LinkedIn"
                whileHover={{ scale: 1.2, color: "#38b2ac" }}
              >
                <AiOutlineLinkedin size={28} />
              </motion.a>
              <motion.a
                href="https://www.github.com"
                target="_blank"
                rel="noreferrer"
                className="text-neutral-400 hover:text-accent transition-colors"
                aria-label="GitHub"
                whileHover={{ scale: 1.2, color: "#38b2ac" }}
              >
                <AiOutlineGithub size={28} />
              </motion.a>
            </motion.div>
          </motion.div>
          
          <motion.div 
            className="hero-img order-1 lg:order-2 relative flex justify-center lg:justify-end select-none"
            variants={imageContainerVariants}
            initial="hidden"
            animate="visible"
          >
            <div className="relative w-80 h-80 md:w-[400px] md:h-[400px] flex items-center justify-center">
              {/* Outer spinning rings */}
              <div className="absolute w-[110%] h-[110%] rounded-full border border-accent/10 animate-spin-slow"></div>
              <div className="absolute w-[105%] h-[105%] rounded-full border border-accent/20 animate-spin-reverse"></div>
              
              {/* Main spinning light rings */}
              <div className="absolute w-full h-full rounded-full border-2 border-accent/30 animate-spin-slow"></div>
              <div className="absolute w-[98%] h-[98%] rounded-full border border-accent/20 animate-spin-reverse"></div>
              <div className="absolute w-[95%] h-[95%] rounded-full border border-accent/40 animate-spin-slow" style={{ animationDuration: '15s' }}></div>
              
              {/* Light beams */}
              <div className="absolute inset-0 w-full h-full animate-spin-slow" style={{ animationDuration: '20s' }}>
                <motion.div 
                  className="absolute top-0 left-1/2 -translate-x-1/2 w-1 h-20 bg-gradient-to-b from-accent/80 to-transparent"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.6 }}
                ></motion.div>
                <motion.div 
                  className="absolute bottom-0 left-1/2 -translate-x-1/2 w-1 h-20 bg-gradient-to-t from-accent/80 to-transparent"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.8 }}
                ></motion.div>
                <motion.div 
                  className="absolute left-0 top-1/2 -translate-y-1/2 w-20 h-1 bg-gradient-to-r from-accent/80 to-transparent"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.0 }}
                ></motion.div>
                <motion.div 
                  className="absolute right-0 top-1/2 -translate-y-1/2 w-20 h-1 bg-gradient-to-l from-accent/80 to-transparent"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 1.2 }}
                ></motion.div>
              </div>
              
              {/* Rotating dots */}
              <div className="absolute w-full h-full animate-spin-reverse" style={{ animationDuration: '10s' }}>
                <motion.div 
                  className="absolute top-0 left-1/2 -translate-x-1/2 w-2 h-2 bg-accent rounded-full"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1.4, duration: 0.3 }}
                ></motion.div>
                <motion.div 
                  className="absolute bottom-0 left-1/2 -translate-x-1/2 w-2 h-2 bg-accent rounded-full"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1.6, duration: 0.3 }}
                ></motion.div>
                <motion.div 
                  className="absolute left-0 top-1/2 -translate-y-1/2 w-2 h-2 bg-accent rounded-full"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1.8, duration: 0.3 }}
                ></motion.div>
                <motion.div 
                  className="absolute right-0 top-1/2 -translate-y-1/2 w-2 h-2 bg-accent rounded-full"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 2.0, duration: 0.3 }}
                ></motion.div>
              </div>
              
              {/* Glowing circle */}
              <motion.div 
                className="absolute w-[92%] h-[92%] rounded-full bg-gradient-to-t from-accent/30 to-transparent/5 animate-pulse"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.7, duration: 1 }}
              ></motion.div>
              
              {/* Main image container */}
              <motion.div 
                className="relative z-10 w-[85%] h-[85%] rounded-full bg-gradient-to-t from-accent/40 to-primary/30 p-2 flex items-center justify-center shadow-xl overflow-hidden"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, ease: [0.175, 0.885, 0.32, 1.275] }}
              >
                <img
                  src={HeroImg}
                  alt="Porter Killian"
                  className="object-cover rounded-full w-full h-full shadow-lg"
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
        
        <motion.div 
          className="hidden lg:flex items-center justify-center mt-8 select-none"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 2.2, duration: 0.5 }}
        >
          <motion.a 
            href="/#about" 
            className="flex items-center flex-col text-neutral-400 hover:text-accent transition-colors"
            aria-label="Scroll to About section"
            onClick={handleScrollToAbout}
            whileHover={{ y: 5, color: "#38b2ac" }}
          >
            <span className="mb-2">Scroll Down</span>
            <AiOutlineArrowDown size={24} className="animate-bounce" />
          </motion.a>
        </motion.div>
      </div>
    </section>
  );
};

export default Hero;
