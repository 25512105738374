import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import ProjectSlider from "./ProjectSlider";

const Projects = () => {
  const titleRef = useRef(null);
  const sliderRef = useRef(null);
  const isTitleInView = useInView(titleRef, { once: false, amount: 0.3 });
  const isSliderInView = useInView(sliderRef, { once: false, amount: 0.1 });
  
  // Animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };
  
  const descriptionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, delay: 0.2, ease: "easeOut" }
    }
  };
  
  const sliderVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" }
    }
  };

  const projects = [
    {
      img: "/projects/shackle.png",
      title: "Shackleco.com",
      desc: "Solo developer for an online market place website built from scratch using the Materialize framework, JS, and Firebase hosting. The final website includes processing payment(Stripe API) and an automated mailing system(NodeJS through firebase).",
      youtube: "none",
      code: "https://github.com/PKill27/ShackleCo",
      tags: ["JavaScript", "Firebase", "Stripe API", "Node.js"]
    },
    {
      img: "/projects/mc.png",
      title: "Mini-minecraft",
      desc: "3 person final project for cis 460(computer graphics), Coded in C++. Together me and my group implemented many features such as: terrain generation(procedurally generated), player movement, sheep + AI for sheep, Trees and different biomes.",
      youtube: "kj2i6JXxjOc",
      code: "none",
      tags: ["C++", "OpenGL", "Procedural Generation", "3D Graphics"]
    },
    {
      img: "/projects/localmedia.png",
      title: "Local Media",
      desc: "Final project for android development in Kotlin, the project is a social media app in which pictures are taken at a location and the pictures that are uploaded near the current location can be viewed based off of the maximum distance set.",
      code: "https://github.com/PKill27/Andriod-Kotlin",
      youtube: "rse81GGyq-4",
      tags: ["Kotlin", "Android", "Geolocation", "Firebase"]
    },
    {
      img: "/projects/2048.png",
      title: "2048 reinforcement learning",
      desc: "Final project for machine learning course, Coded in python. Used multiple different approaches of reinforcement learning to try and play 2048 effectively.",
      youtube: "aacsC9dmVHw",
      code: "none",
      tags: ["Python", "Machine Learning", "Reinforcement Learning"]
    },
    {
      img: "/projects/pacman.png",
      title: "Pacman",
      desc: "Freshman fall final project, implemented paceman from scratch in java. Included high score functionality, ghost ai, and collision detection.",
      youtube: "none",
      code: "https://github.com/PKill27/Pacman",
      tags: ["Java", "Game Development", "AI"]
    },
  ];

  return (
    <section className="section bg-gradient-to-b from-secondary to-primary" id="projects">
      <div className="container">
        <div className="mb-16 text-center" ref={titleRef}>
          <motion.h2 
            className="section-title mx-auto"
            variants={titleVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            Projects
          </motion.h2>
          <motion.p 
            className="mt-4 text-neutral-300 max-w-xl mx-auto"
            variants={descriptionVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            Here are some of my favorite projects showcasing my skills in different technologies and domains.
          </motion.p>
        </div>

        <motion.div 
          className="projects-container"
          ref={sliderRef}
          variants={sliderVariants}
          initial="hidden"
          animate={isSliderInView ? "visible" : "hidden"}
        >
          <ProjectSlider projects={projects} />
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;