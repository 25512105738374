import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence, useScroll, useInView } from "framer-motion";
import Header from "./components/Header.js";
import Hero from "./components/Hero";
import About from "./components/About";
import Project from "./components/Project";
import Art from "./components/Art";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

// Scroll reveal component using Framer Motion
const ScrollReveal = ({ children, threshold = 0.1 }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: threshold });
  
  return (
    <div ref={ref} className="reveal-container">
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        animate={isInView ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        {children}
      </motion.div>
    </div>
  );
};

// Scroll indicator component
const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    const handleScroll = () => {
      // Hide indicator after user has scrolled a bit
      if (window.scrollY > window.innerHeight * 0.5) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <div className={`scroll-indicator ${isVisible ? 'visible' : ''}`}>
      <div className="indicator-dot"></div>
    </div>
  );
};

function App() {
  const [currentSection, setCurrentSection] = useState("home");

  // Add scroll listener for tracking current section (for nav highlighting)
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('.section');
      
      sections.forEach(section => {
        const sectionTop = section.getBoundingClientRect().top;
        const sectionBottom = section.getBoundingClientRect().bottom;
        const threshold = window.innerHeight * 0.5;
        
        // If the section is in the viewport
        if (sectionTop < threshold && sectionBottom > 0) {
          const sectionId = section.getAttribute('id');
          if (sectionId) {
            setCurrentSection(sectionId);
          }
        }
      });
    };
    
    window.addEventListener('scroll', handleScroll);
    // Initial check
    setTimeout(handleScroll, 100);
    
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle smooth scrolling for anchor links
  useEffect(() => {
    const handleAnchorClick = (e) => {
      const target = e.target.closest('a');
      if (!target) return;
      
      // Check if it's an anchor link
      const href = target.getAttribute('href');
      if (!href || !href.startsWith('/#')) return;
      
      e.preventDefault();
      
      const targetId = href.substring(2); // Remove the /# part
      const element = document.getElementById(targetId);
      
      if (element) {
        // Use smooth scrolling for better user experience
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    
    document.body.addEventListener('click', handleAnchorClick);
    
    return () => document.body.removeEventListener('click', handleAnchorClick);
  }, []);

  // Prevent cursor I-beam and text selection on non-text elements
  useEffect(() => {
    // Disable I-beam cursor on non-text elements
    const preventIBeamCursor = (e) => {
      // If the target is not a text element, ensure default cursor
      const isTextElement = ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'SPAN', 'LI'].includes(e.target.tagName);
      const isContentEditable = e.target.isContentEditable;
      const isInputElement = ['INPUT', 'TEXTAREA'].includes(e.target.tagName);
      
      if (!isTextElement && !isContentEditable && !isInputElement) {
        e.preventDefault();
      }
    };
    
    document.addEventListener('mousedown', preventIBeamCursor);
    
    return () => {
      document.removeEventListener('mousedown', preventIBeamCursor);
    };
  }, []);

  // Animation variants for Framer Motion
  const fadeInUp = {
    initial: { opacity: 0, y: 60 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6, ease: "easeOut" }
  };

  return (
    <div className="app-container">
      <Header currentSection={currentSection} />
      
      <div className="sections-container">
        <Hero />
        
        <ScrollReveal threshold={0.2}>
          <About />
        </ScrollReveal>
        
        <ScrollReveal threshold={0.2}>
          <Project />
        </ScrollReveal>
        
        <ScrollReveal threshold={0.2}>
          <Art />
        </ScrollReveal>
        
        <ScrollReveal threshold={0.2}>
          <Contact />
        </ScrollReveal>
        
        <Footer />
      </div>
      
      <ScrollIndicator />
    </div>
  );
}

export default App;
export { ScrollReveal };
