import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import AboutImg from "../assets/about-img.jpg";
import { AiOutlineCode, AiOutlineDatabase, AiOutlineLayout } from "react-icons/ai";
import { ScrollReveal } from "../App";

const About = () => {
  const skillsRef = useRef(null);
  const isSkillsInView = useInView(skillsRef, { once: false, amount: 0.2 });

  const imgRef = useRef(null);
  const isImgInView = useInView(imgRef, { once: false, amount: 0.2 });

  const textRef = useRef(null);
  const isTextInView = useInView(textRef, { once: false, amount: 0.2 });

  const skills = [
    {
      icon: <AiOutlineCode size={24} />,
      title: "Languages",
      items: ["JavaScript", "Python", "Java", "C++", "SQL"]
    },
    {
      icon: <AiOutlineLayout size={24} />,
      title: "Frontend",
      items: ["React", "Tailwind CSS", "HTML/CSS", "Responsive Design"]
    },
    {
      icon: <AiOutlineDatabase size={24} />,
      title: "Data",
      items: ["SQL", "NoSQL", "Spark", "Data Analysis"]
    }
  ];

  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  return (
    <section className="section bg-gradient-to-b from-primary to-secondary" id="about">
      <div className="container">
        <div className="mb-16 text-center">
          <motion.h2 
            className="section-title mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            About Me
          </motion.h2>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="about-img order-2 lg:order-1" ref={imgRef}>
            <motion.div 
              className="relative"
              initial={{ opacity: 0, x: -50 }}
              animate={isImgInView ? { opacity: 1, x: 0 } : { opacity: 0, x: -50 }}
              transition={{ duration: 0.6 }}
            >
              <div className="w-full max-w-md mx-auto overflow-hidden rounded-lg shadow-lg">
                <img
                  src={AboutImg}
                  alt="Porter Killian"
                  className="w-full h-auto object-cover"
                />
              </div>
              <div className="absolute -bottom-4 -right-4 w-24 h-24 bg-accent rounded-lg z-[-1]"></div>
              <div className="absolute -top-4 -left-4 w-24 h-24 border-2 border-accent rounded-lg z-[-1]"></div>
            </motion.div>
          </div>

          <div className="about-content order-1 lg:order-2" ref={textRef}>
            <motion.div 
              className="space-y-6 text-neutral-200"
              initial={{ opacity: 0, x: 50 }}
              animate={isTextInView ? { opacity: 1, x: 0 } : { opacity: 0, x: 50 }}
              transition={{ duration: 0.6 }}
            >
              <p>
                I am a recent graduate from the University of Pennsylvania, where I earned a Bachelor of Applied Science (BAS) degree in computer science in 2023. My time at Penn has been an incredible journey, filled with challenging coursework and exciting projects that have shaped my passion for technology and problem-solving.
              </p>

              <p>
                I have a strong foundation in software development, starting with Java. I excel in database management, including SQL, NoSQL, and Spark, often combined with Python for data analysis.
              </p>

              <p>
                I'm passionate about web development, using JavaScript and React with Tailwind CSS. I have used C++ primarily for computer graphics and animation, and I'm also proficient in graphic design, utilizing Adobe Photoshop and Illustrator.
              </p>
            </motion.div>

            <div className="mt-10" ref={skillsRef}>
              <motion.h3 
                className="text-xl font-semibold mb-6 text-accent"
                initial={{ opacity: 0, y: 20 }}
                animate={isSkillsInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
                transition={{ duration: 0.5 }}
              >
                Technical Skills
              </motion.h3>
              <motion.div 
                className="grid md:grid-cols-3 gap-6"
                variants={staggerContainer}
                initial="hidden"
                animate={isSkillsInView ? "visible" : "hidden"}
              >
                {skills.map((skill, index) => (
                  <motion.div 
                    key={index} 
                    className="card p-6"
                    variants={fadeInUp}
                  >
                    <div className="flex items-center mb-4">
                      <span className="text-accent mr-3">{skill.icon}</span>
                      <h4 className="font-medium text-lg">{skill.title}</h4>
                    </div>
                    <ul className="space-y-2 text-neutral-300 text-sm">
                      {skill.items.map((item, i) => (
                        <li key={i} className="flex items-center">
                          <span className="w-1.5 h-1.5 rounded-full bg-accent mr-2"></span>
                          {item}
                        </li>
                      ))}
                    </ul>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;