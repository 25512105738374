// Modal.js
import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineClose, AiOutlineGithub, AiOutlineYoutube } from "react-icons/ai";

const ModalProjects = ({ image, title, desc, youtube, code, tags, onClose }) => {
  // Animation variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.2 }
    }
  };
  
  const modalVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1,
      transition: { 
        duration: 0.4,
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: { 
      opacity: 0, 
      y: -20, 
      scale: 0.95,
      transition: { duration: 0.3 }
    }
  };
  
  const buttonVariants = {
    hover: { 
      scale: 1.1,
      transition: { duration: 0.2 }
    },
    tap: { scale: 0.95 }
  };
  
  const contentVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { 
        duration: 0.5,
        delay: 0.2,
        staggerChildren: 0.1 
      }
    }
  };
  
  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };
  
  const tagVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: (i) => ({ 
      opacity: 1, 
      scale: 1,
      transition: { 
        duration: 0.3, 
        delay: 0.05 * i 
      }
    })
  };
  
  // Handle escape key to close modal
  useEffect(() => {
    // Prevent scrolling of background when modal is open
    document.body.style.overflow = 'hidden';
    
    const handleEsc = (e) => {
      if (e.key === 'Escape') onClose();
    };
    
    window.addEventListener('keydown', handleEsc);
    
    // Cleanup function
    return () => {
      document.body.style.overflow = 'auto';
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <motion.div 
      className="modal-overlay" 
      onClick={onClose}
      variants={overlayVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div 
        className="modal-content max-w-5xl mx-auto"
        onClick={(e) => e.stopPropagation()}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex justify-between items-center p-4 border-b border-neutral-700">
          <h3 className="text-xl font-medium text-neutral-100">{title}</h3>
          <motion.button 
            className="p-2 text-neutral-400 hover:text-accent transition-colors rounded-full hover:bg-neutral-700" 
            onClick={onClose}
            aria-label="Close"
            variants={buttonVariants}
            whileHover="hover"
            whileTap="tap"
          >
            <AiOutlineClose size={20} />
          </motion.button>
        </div>
        
        <motion.div 
          className="modal-inner p-6"
          variants={contentVariants}
          initial="hidden"
          animate="visible"
        >
          <div className="grid md:grid-cols-2 gap-8">
            <motion.div 
              className="project-media rounded-lg overflow-hidden"
              variants={itemVariants}
            >
              {youtube !== "none" ? (
                <div className="aspect-video relative overflow-hidden">
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src={`https://www.youtube.com/embed/${youtube}`}
                    title={title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ) : (
                <motion.img 
                  src={image} 
                  alt={title} 
                  className="w-full h-auto rounded-lg object-cover" 
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                />
              )}
            </motion.div>
            
            <div className="project-info">
              <motion.div 
                className="mb-6"
                variants={itemVariants}
              >
                <h4 className="text-lg font-semibold mb-2 text-accent">Description</h4>
                <p className="text-neutral-300">{desc}</p>
              </motion.div>
              
              {tags && tags.length > 0 && (
                <motion.div 
                  className="mb-6"
                  variants={itemVariants}
                >
                  <h4 className="text-lg font-semibold mb-2 text-accent">Technologies</h4>
                  <div className="flex flex-wrap gap-2">
                    {tags.map((tag, index) => (
                      <motion.span 
                        key={index} 
                        className="px-3 py-1 bg-primary rounded-full text-neutral-300 text-sm"
                        variants={tagVariants}
                        custom={index}
                        whileHover={{ 
                          scale: 1.05, 
                          backgroundColor: "rgba(56, 178, 172, 0.3)" 
                        }}
                      >
                        {tag}
                      </motion.span>
                    ))}
                  </div>
                </motion.div>
              )}
              
              <motion.div 
                className="flex flex-wrap gap-4"
                variants={itemVariants}
              >
                {code !== "none" && (
                  <motion.a
                    href={code} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-primary flex items-center"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                  >
                    <AiOutlineGithub className="mr-2" size={20} />
                    View Code
                  </motion.a>
                )}
                
                {youtube !== "none" && (
                  <motion.a
                    href={`https://www.youtube.com/watch?v=${youtube}`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="btn btn-outline flex items-center"
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.97 }}
                  >
                    <AiOutlineYoutube className="mr-2" size={20} />
                    Watch on YouTube
                  </motion.a>
                )}
              </motion.div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ModalProjects;
