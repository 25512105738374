import React from "react";
import { AiOutlineHeart } from "react-icons/ai";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="bg-primary-dark py-8 text-neutral-400 text-center">
      <div className="container">
        <div className="flex flex-col md:flex-row items-center justify-center md:justify-between">
          <div className="mb-4 md:mb-0">
            <a href="/" className="font-display text-lg font-semibold text-accent">
              Porter Killian
            </a>
          </div>
          
          <div className="flex items-center">
            <span>© {currentYear} All rights reserved</span>
            <span className="mx-2">|</span>
            <div className="flex items-center">
              <span>Made with</span>
              <AiOutlineHeart className="text-accent mx-1" />
              <span>using React & Tailwind CSS</span>
            </div>
          </div>
          
          <div className="hidden md:block">
            <a 
              href="#home" 
              className="text-neutral-400 hover:text-accent transition-colors"
            >
              Back to top
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;