import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import { AiOutlineMail, AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";

const Contact = () => {
  const titleRef = useRef(null);
  const cardRef = useRef(null);
  const isTitleInView = useInView(titleRef, { once: false, amount: 0.3 });
  const isCardInView = useInView(cardRef, { once: false, amount: 0.1 });
  
  // Animation variants
  const titleVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };
  
  const descriptionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.6, delay: 0.2, ease: "easeOut" }
    }
  };
  
  const cardVariants = {
    hidden: { opacity: 0, y: 40 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.8, ease: "easeOut" }
    }
  };
  
  const listItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i) => ({ 
      opacity: 1, 
      x: 0,
      transition: { 
        duration: 0.5, 
        delay: 0.1 * i, 
        ease: "easeOut" 
      }
    })
  };
  
  const contactItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: (i) => ({ 
      opacity: 1, 
      x: 0,
      transition: { 
        duration: 0.5, 
        delay: 0.15 * i, 
        ease: "easeOut" 
      }
    })
  };

  return (
    <section className="section bg-gradient-to-b from-secondary to-primary" id="contact">
      <div className="container max-w-4xl">
        <div className="mb-16 text-center" ref={titleRef}>
          <motion.h2 
            className="section-title mx-auto"
            variants={titleVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            Get In Touch
          </motion.h2>
          <motion.p 
            className="mt-4 text-neutral-300 max-w-xl mx-auto"
            variants={descriptionVariants}
            initial="hidden"
            animate={isTitleInView ? "visible" : "hidden"}
          >
            I'm currently looking for software development opportunities. Feel free to reach out if you'd like to connect!
          </motion.p>
        </div>

        <motion.div 
          className="card p-8 md:p-12"
          ref={cardRef}
          variants={cardVariants}
          initial="hidden"
          animate={isCardInView ? "visible" : "hidden"}
        >
          <div className="grid md:grid-cols-2 gap-8">
            <div className="contact-info space-y-6">
              <motion.h3 
                className="text-2xl font-semibold text-accent mb-4"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={0}
              >
                Contact Information
              </motion.h3>
              
              <motion.div 
                className="flex items-start space-x-4"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={1}
              >
                <div className="bg-accent/20 p-3 rounded-lg text-accent">
                  <AiOutlineMail size={24} />
                </div>
                <div>
                  <h4 className="text-lg font-medium mb-1">Email</h4>
                  <a href="mailto:Porter.killian@gmail.com" className="text-neutral-300 hover:text-accent transition-colors">
                    Porter.killian@gmail.com
                  </a>
                </div>
              </motion.div>
              
              <motion.div 
                className="flex items-start space-x-4"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={2}
              >
                <div className="bg-accent/20 p-3 rounded-lg text-accent">
                  <AiOutlineLinkedin size={24} />
                </div>
                <div>
                  <h4 className="text-lg font-medium mb-1">LinkedIn</h4>
                  <a 
                    href="https://www.linkedin.com/in/porter27/" 
                    target="_blank" 
                    rel="noreferrer"
                    className="text-neutral-300 hover:text-accent transition-colors"
                  >
                    linkedin.com/in/porter27
                  </a>
                </div>
              </motion.div>
              
              <motion.div 
                className="flex items-start space-x-4"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={3}
              >
                <div className="bg-accent/20 p-3 rounded-lg text-accent">
                  <AiOutlineGithub size={24} />
                </div>
                <div>
                  <h4 className="text-lg font-medium mb-1">GitHub</h4>
                  <a 
                    href="https://github.com/PKill27" 
                    target="_blank" 
                    rel="noreferrer"
                    className="text-neutral-300 hover:text-accent transition-colors"
                  >
                    github.com/PKill27
                  </a>
                </div>
              </motion.div>
            </div>
            
            <div className="hiring-info">
              <motion.h3 
                className="text-2xl font-semibold text-accent mb-4"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={0}
              >
                Looking For
              </motion.h3>
              <ul className="space-y-4 text-neutral-200">
                <motion.li 
                  className="flex items-start"
                  variants={listItemVariants}
                  initial="hidden"
                  animate={isCardInView ? "visible" : "hidden"}
                  custom={1}
                >
                  <span className="text-accent mr-2">•</span>
                  <span>Full-time software engineering positions</span>
                </motion.li>
                <motion.li 
                  className="flex items-start"
                  variants={listItemVariants}
                  initial="hidden"
                  animate={isCardInView ? "visible" : "hidden"}
                  custom={2}
                >
                  <span className="text-accent mr-2">•</span>
                  <span>Frontend or full-stack development roles</span>
                </motion.li>
                <motion.li 
                  className="flex items-start"
                  variants={listItemVariants}
                  initial="hidden"
                  animate={isCardInView ? "visible" : "hidden"}
                  custom={3}
                >
                  <span className="text-accent mr-2">•</span>
                  <span>Opportunities in tech companies or startups</span>
                </motion.li>
                <motion.li 
                  className="flex items-start"
                  variants={listItemVariants}
                  initial="hidden"
                  animate={isCardInView ? "visible" : "hidden"}
                  custom={4}
                >
                  <span className="text-accent mr-2">•</span>
                  <span>Challenging projects that combine technology and creativity</span>
                </motion.li>
              </ul>
              
              <motion.div 
                className="mt-8"
                variants={contactItemVariants}
                initial="hidden"
                animate={isCardInView ? "visible" : "hidden"}
                custom={5}
              >
                <a 
                  href="/resume.pdf" 
                  target="_blank" 
                  rel="noreferrer"
                  className="btn btn-primary w-full"
                >
                  Download Resume
                </a>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Contact;