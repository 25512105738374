import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Header = ({ currentSection }) => {
  const [toggle, setToggle] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  // Update active section when currentSection changes
  useEffect(() => {
    if (currentSection) {
      setActiveSection(currentSection);
    }
  }, [currentSection]);

  const handleToggle = () => setToggle(!toggle);

  // Add scroll effect for header and track active section
  useEffect(() => {
    const handleScroll = () => {
      // Header background effect
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }

      // Track current section for nav highlighting if not provided by parent
      if (!currentSection) {
        const sections = document.querySelectorAll('.section');
        const scrollPosition = window.scrollY + window.innerHeight / 3;
        
        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;
          const sectionId = section.getAttribute('id');
          
          if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
            setActiveSection(sectionId);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentSection]);

  // Navigate to section with smooth scroll
  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    const section = document.getElementById(sectionId);
    
    if (section) {
      // Use smooth scroll for better UX
      window.scrollTo({
        top: section.offsetTop - 80, // Account for header height
        behavior: 'smooth'
      });
      
      // Close mobile menu if open
      if (toggle) {
        setToggle(false);
      }
    }
  };

  // Animation variants for links
  const linkVariants = {
    hover: { 
      scale: 1.05,
      color: "#38b2ac",
      transition: { duration: 0.2 }
    }
  };

  // Animation variants for active indicator
  const activeIndicatorVariants = {
    initial: { width: 0, opacity: 0 },
    animate: { width: "100%", opacity: 1, transition: { duration: 0.3 } }
  };

  // Close mobile menu on click outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (toggle && !e.target.closest('.mobile-nav') && !e.target.closest('button')) {
        setToggle(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [toggle]);

  // Add scroll lock when mobile menu is open
  useEffect(() => {
    if (toggle) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    
    return () => {
      document.body.style.overflow = '';
    };
  }, [toggle]);

  return (
    <motion.header 
      className={`fixed w-full z-50 select-none ${scrolled ? 'bg-primary shadow-lg py-3' : 'bg-primary bg-opacity-95 py-5'}`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      <div className="container mx-auto flex justify-between items-center">
        <motion.a 
          href="/#home" 
          className="font-display text-2xl font-bold text-accent transition-colors hover:text-accent-light"
          onClick={(e) => handleNavClick(e, 'home')}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Porter
        </motion.a>

        {/* Desktop Nav */}
        <nav className="hidden lg:block">
          <ul className="flex items-center space-x-1">
            <li>
              <motion.a 
                href="/#about" 
                className={`px-4 py-2 transition-colors relative ${activeSection === 'about' ? 'text-accent' : ''}`}
                onClick={(e) => handleNavClick(e, 'about')}
                whileHover="hover"
                variants={linkVariants}
              >
                About
                {activeSection === 'about' && (
                  <motion.span 
                    className="absolute bottom-0 left-0 h-0.5 bg-accent"
                    variants={activeIndicatorVariants}
                    initial="initial"
                    animate="animate"
                  ></motion.span>
                )}
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#projects" 
                className={`px-4 py-2 transition-colors relative ${activeSection === 'projects' ? 'text-accent' : ''}`}
                onClick={(e) => handleNavClick(e, 'projects')}
                whileHover="hover"
                variants={linkVariants}
              >
                Projects
                {activeSection === 'projects' && (
                  <motion.span 
                    className="absolute bottom-0 left-0 h-0.5 bg-accent"
                    variants={activeIndicatorVariants}
                    initial="initial"
                    animate="animate"
                  ></motion.span>
                )}
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#art" 
                className={`px-4 py-2 transition-colors relative ${activeSection === 'art' ? 'text-accent' : ''}`}
                onClick={(e) => handleNavClick(e, 'art')}
                whileHover="hover"
                variants={linkVariants}
              >
                Art
                {activeSection === 'art' && (
                  <motion.span 
                    className="absolute bottom-0 left-0 h-0.5 bg-accent"
                    variants={activeIndicatorVariants}
                    initial="initial"
                    animate="animate"
                  ></motion.span>
                )}
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#contact" 
                className={`px-4 py-2 transition-colors relative ${activeSection === 'contact' ? 'text-accent' : ''}`}
                onClick={(e) => handleNavClick(e, 'contact')}
                whileHover="hover"
                variants={linkVariants}
              >
                Contact
                {activeSection === 'contact' && (
                  <motion.span 
                    className="absolute bottom-0 left-0 h-0.5 bg-accent"
                    variants={activeIndicatorVariants}
                    initial="initial"
                    animate="animate"
                  ></motion.span>
                )}
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/resume.pdf" 
                target="_blank" 
                rel="noreferrer"
                className="ml-2 btn btn-outline"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Resume
              </motion.a>
            </li>
          </ul>
        </nav>

        {/* Mobile Nav */}
        <motion.nav 
          className={toggle ? "mobile-nav open left-0 select-none" : "mobile-nav left-[-100%] select-none"}
          initial={false}
          animate={toggle ? { x: "100%" } : { x: 0 }}
        >
          <ul className="flex flex-col">
            <li>
              <motion.a 
                href="/#about" 
                onClick={(e) => handleNavClick(e, 'about')}
                whileHover={{ x: 10, color: "#38b2ac" }}
              >
                About
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#projects" 
                onClick={(e) => handleNavClick(e, 'projects')}
                whileHover={{ x: 10, color: "#38b2ac" }}
              >
                Projects
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#art" 
                onClick={(e) => handleNavClick(e, 'art')}
                whileHover={{ x: 10, color: "#38b2ac" }}
              >
                Art
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/#contact" 
                onClick={(e) => handleNavClick(e, 'contact')}
                whileHover={{ x: 10, color: "#38b2ac" }}
              >
                Contact
              </motion.a>
            </li>
            <li>
              <motion.a 
                href="/resume.pdf" 
                target="_blank" 
                rel="noreferrer" 
                onClick={handleToggle} 
                className="btn btn-primary mt-6"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Resume
              </motion.a>
            </li>
          </ul>
        </motion.nav>

        {/* Toggle button */}
        <motion.button 
          onClick={handleToggle} 
          className="block lg:hidden z-50 p-2 text-accent focus:outline-none"
          aria-label="Toggle menu"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          {!toggle ? <AiOutlineMenu size={26} /> : <AiOutlineClose size={26} />}
        </motion.button>
      </div>
    </motion.header>
  );
};

export default Header;