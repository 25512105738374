import { useState, useRef, useEffect } from 'react';
import ModalProjects from "./ModalProjects"

const ProjectSlider = ({ projects }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  const openModal = (project) => {
    setSelectedProject(project);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProject(null);
  };
  
  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="relative">
      <div className="carousel rounded-xl overflow-hidden">
        {/* Navigation buttons */}
        <div className="flex justify-between absolute top-0 left-0 right-0 bottom-0 z-10 pointer-events-none">
          <button
            onClick={movePrev}
            className={`pointer-events-auto w-12 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed transition-all ease-in-out duration-300 bg-gradient-to-r from-primary/60 to-transparent ${isDisabled('prev') ? 'hidden' : ''}`}
            disabled={isDisabled('prev')}
            aria-label="Previous"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto text-accent"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            onClick={moveNext}
            className={`pointer-events-auto w-12 h-full text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed transition-all ease-in-out duration-300 bg-gradient-to-l from-primary/60 to-transparent ${isDisabled('next') ? 'hidden' : ''}`}
            disabled={isDisabled('next')}
            aria-label="Next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto text-accent"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      
        {/* Carousel container */}
        <div
          ref={carousel}
          className="carousel-container relative flex gap-4 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0 py-2"
        >
          {projects.map((project, index) => (
            <div
              key={index}
              className="carousel-item relative w-full sm:w-1/2 lg:w-1/3 snap-start px-2"
            >
              <div className="card overflow-hidden h-full flex flex-col">
                <div 
                  className="relative aspect-video bg-cover bg-center"
                  style={{ backgroundImage: `url(${project.img || ''})` }}
                >
                  <img 
                    src={project.img || ''}
                    alt={project.title}
                    className="w-full aspect-video object-cover object-center opacity-0"
                  />
                </div>
                
                <div className="p-6 flex flex-col flex-grow">
                  <h3 className="text-xl font-semibold mb-2 text-neutral-100">{project.title}</h3>
                  
                  <p className="text-neutral-300 text-sm line-clamp-3 mb-4">
                    {project.desc}
                  </p>
                  
                  <div className="flex flex-wrap gap-2 mb-6">
                    {project.tags && project.tags.map((tag, i) => (
                      <span key={i} className="text-xs py-1 px-2 bg-primary rounded-full text-neutral-300">
                        {tag}
                      </span>
                    ))}
                  </div>
                  
                  <div className="mt-auto">
                    <button
                      onClick={() => openModal(project)}
                      className="btn btn-outline w-full text-sm"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Pagination dots */}
      <div className="flex justify-center mt-8">
        {Array.from({ length: Math.ceil(maxScrollWidth.current / carousel.current?.offsetWidth || 1) + 1 }).map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 mx-1 rounded-full transition-colors ${
              index === currentIndex ? 'bg-accent' : 'bg-neutral-600 hover:bg-neutral-500'
            }`}
            aria-label={`Go to slide ${index + 1}`}
          />
        ))}
      </div>
      
      {/* Modal */}
      {modalOpen && selectedProject && (
        <ModalProjects 
          image={selectedProject.img} 
          title={selectedProject.title} 
          desc={selectedProject.desc} 
          youtube={selectedProject.youtube} 
          code={selectedProject.code} 
          tags={selectedProject.tags}
          onClose={closeModal} 
        />
      )}
    </div>
  );
};

export default ProjectSlider;
