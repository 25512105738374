import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineClose, AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";

const Modal = ({ image, title, onClose }) => {
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  // Animation variants
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.2 }
    }
  };
  
  const modalVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: { 
      opacity: 1, 
      y: 0, 
      scale: 1,
      transition: { 
        duration: 0.4,
        type: "spring",
        stiffness: 300,
        damping: 30
      }
    },
    exit: { 
      opacity: 0, 
      y: -20, 
      scale: 0.95,
      transition: { duration: 0.3 }
    }
  };
  
  const buttonVariants = {
    hover: { 
      scale: 1.1,
      transition: { duration: 0.2 }
    },
    tap: { scale: 0.95 }
  };

  useEffect(() => {
    // Create a new image element
    const img = new Image();
    img.src = image;

    // When the image loads, determine its orientation
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      const horizontal = imgWidth > imgHeight;
      setIsHorizontal(horizontal);
    };
    
    // Prevent scrolling of background when modal is open
    document.body.style.overflow = 'hidden';
    
    // Cleanup function
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [image]);

  // Handle escape key to close modal
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === 'Escape') onClose();
    };
    
    window.addEventListener('keydown', handleEsc);
    return () => window.removeEventListener('keydown', handleEsc);
  }, [onClose]);

  const toggleZoom = () => {
    setIsZoomed(!isZoomed);
  };

  return (
    <motion.div 
      className="modal-overlay" 
      onClick={onClose}
      variants={overlayVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div 
        className="modal-content max-w-5xl mx-auto"
        onClick={(e) => e.stopPropagation()}
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="flex justify-between items-center p-4 border-b border-neutral-700">
          <h3 className="text-xl font-medium text-neutral-100">{title}</h3>
          <div className="flex space-x-2">
            <motion.button 
              onClick={toggleZoom}
              className="p-2 text-neutral-400 hover:text-accent transition-colors rounded-full hover:bg-neutral-700"
              aria-label={isZoomed ? "Zoom Out" : "Zoom In"}
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              {isZoomed ? <AiOutlineZoomOut size={20} /> : <AiOutlineZoomIn size={20} />}
            </motion.button>
            <motion.button 
              className="p-2 text-neutral-400 hover:text-accent transition-colors rounded-full hover:bg-neutral-700" 
              onClick={onClose}
              aria-label="Close"
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <AiOutlineClose size={20} />
            </motion.button>
          </div>
        </div>
        
        <div 
          className={`modal-inner p-4 flex items-center justify-center overflow-hidden ${isZoomed ? 'cursor-zoom-out' : 'cursor-zoom-in'}`} 
          onClick={toggleZoom}
        >
          <motion.img 
            src={image} 
            alt={title} 
            className={`
              max-h-[70vh] object-contain
              ${isHorizontal ? 'max-w-full' : 'max-w-[80%]'}
            `}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ 
              opacity: 1, 
              scale: isZoomed ? 1.5 : 1,
              transition: { 
                opacity: { duration: 0.3 },
                scale: { duration: 0.5, type: "spring", stiffness: 200, damping: 25 }
              }
            }}
          />
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
