import { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Modal from "./Modal"

const Carousel = ({ projects }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const maxScrollWidth = useRef(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carousel = useRef(null);

  // Animation variants
  const buttonVariants = {
    rest: { opacity: 0.75, scale: 1 },
    hover: { opacity: 1, scale: 1.05 },
    tap: { scale: 0.95 }
  };
  
  const imageVariants = {
    hover: { 
      scale: 1.1,
      transition: { duration: 0.5 }
    }
  };
  
  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    }
  };
  
  const dotVariants = {
    inactive: { scale: 1 },
    active: { scale: 1.2 },
    hover: { scale: 1.1 }
  };

  const movePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const moveNext = () => {
    if (
      carousel.current !== null &&
      carousel.current.offsetWidth * currentIndex <= maxScrollWidth.current
    ) {
      setCurrentIndex((prevState) => prevState + 1);
    }
  };

  const isDisabled = (direction) => {
    if (direction === 'prev') {
      return currentIndex <= 0;
    }

    if (direction === 'next' && carousel.current !== null) {
      return (
        carousel.current.offsetWidth * currentIndex >= maxScrollWidth.current
      );
    }

    return false;
  };

  const openModal = (image, title) => {
    setSelectedImage(image);
    setSelectedTitle(title);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
    setSelectedTitle(null);
  };
  
  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = carousel.current.offsetWidth * currentIndex;
    }
  }, [currentIndex]);

  useEffect(() => {
    maxScrollWidth.current = carousel.current
      ? carousel.current.scrollWidth - carousel.current.offsetWidth
      : 0;
  }, []);

  return (
    <div className="relative">
      <div className="carousel rounded-xl overflow-hidden">
        {/* Navigation buttons */}
        <div className="flex justify-between absolute top-0 left-0 right-0 bottom-0 z-10 pointer-events-none">
          <motion.button
            onClick={movePrev}
            className={`pointer-events-auto w-12 h-full text-center disabled:opacity-25 disabled:cursor-not-allowed transition-all ease-in-out duration-300 bg-gradient-to-r from-secondary/60 to-transparent ${isDisabled('prev') ? 'hidden' : ''}`}
            disabled={isDisabled('prev')}
            aria-label="Previous"
            variants={buttonVariants}
            initial="rest"
            whileHover="hover"
            whileTap="tap"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto text-accent"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </motion.button>
          <motion.button
            onClick={moveNext}
            className={`pointer-events-auto w-12 h-full text-center disabled:opacity-25 disabled:cursor-not-allowed transition-all ease-in-out duration-300 bg-gradient-to-l from-secondary/60 to-transparent ${isDisabled('next') ? 'hidden' : ''}`}
            disabled={isDisabled('next')}
            aria-label="Next"
            variants={buttonVariants}
            initial="rest"
            whileHover="hover"
            whileTap="tap"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 mx-auto text-accent"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </motion.button>
        </div>
      
        {/* Carousel container */}
        <div
          ref={carousel}
          className="carousel-container relative flex gap-4 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-0 py-2"
        >
          {projects.map((artwork, index) => (
            <motion.div
              key={index}
              className="carousel-item relative w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5 snap-start px-2"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.05 }}
            >
              <motion.div 
                className="card overflow-hidden cursor-pointer"
                onClick={() => openModal(artwork.img, artwork.title)}
                whileHover={{ y: -8 }}
                transition={{ duration: 0.3 }}
              >
                <div className="aspect-square overflow-hidden">
                  <motion.img 
                    src={artwork.img || ''}
                    alt={artwork.title}
                    className="w-full h-full object-cover"
                    variants={imageVariants}
                    whileHover="hover"
                  />
                </div>
                
                <motion.div 
                  className="absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent flex items-end p-4"
                  variants={overlayVariants}
                  initial="hidden"
                  whileHover="visible"
                >
                  <h3 className="text-white text-lg font-medium">
                    {artwork.title}
                  </h3>
                </motion.div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
      
      {/* Pagination dots */}
      <div className="flex justify-center mt-8">
        {Array.from({ length: Math.ceil(maxScrollWidth.current / carousel.current?.offsetWidth || 1) + 1 }).map((_, index) => (
          <motion.button
            key={index}
            onClick={() => setCurrentIndex(index)}
            className={`w-3 h-3 mx-1 rounded-full ${
              index === currentIndex ? 'bg-accent' : 'bg-neutral-600'
            }`}
            aria-label={`Go to slide ${index + 1}`}
            variants={dotVariants}
            initial={index === currentIndex ? "active" : "inactive"}
            animate={index === currentIndex ? "active" : "inactive"}
            whileHover="hover"
          />
        ))}
      </div>
      
      {/* Modal */}
      <AnimatePresence>
        {modalOpen && selectedImage && (
          <Modal 
            image={selectedImage} 
            title={selectedTitle}
            onClose={closeModal} 
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Carousel;
